import React from 'react'
import styled from 'styled-components'
import { BREAKPOINTS } from '../components/tokens'

const StatsContainer = styled.div`
    text-align: center;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (min-width: ${BREAKPOINTS.tablet}px) {
        justify-content: space-between;
    }
`

const StatBox = styled.div`
    display: flex;
    background: rgba(30, 112, 161, 0.12);
    border-radius: 12px !important;
    width: 48%;
    height: 102px;
    color: rgb(30, 112, 161);
    line-height: 22px;
    font-family: Bryant, sans-serif;
    letter-spacing: -0.0125rem;
    vertical-align: middle;
    margin: 12px 0px;

    @media (min-width: ${BREAKPOINTS.tablet}px) and (max-width: ${BREAKPOINTS.desktop}px) {
        width: 23%;
        height: 102px;
    }
    @media (min-width: ${BREAKPOINTS.desktop}px) {
        width: 144px;
        height: 82px;
    }
`
const StatContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`
const StyledFigure = styled.div`
    font-size: 22px;
    font-weight: 700;
    margin: 0;
    width: 144px;
`
const StyledParagraph = styled.div`
    font-family: Bryant, sans-serif;
    line-height: 22px;
    font-size: 18px;
    width: 134px;
`

const Stats = ({ stats }: { stats: { figure: string; label: string }[] }) => {
    return (
        <StatsContainer>
            {stats.map(item => (
                <StatBox key={item.label}>
                    <StatContent>
                        <StyledFigure>{item.figure}</StyledFigure>
                        <StyledParagraph>{item.label}</StyledParagraph>
                    </StatContent>
                </StatBox>
            ))}
        </StatsContainer>
    )
}
export default Stats

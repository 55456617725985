import React from 'react'
import styled from 'styled-components'

const EnhancedCopyContainer = styled.div`
    position: relative;
`
type EnhancedCopyProps = {
    enhancedCopy: string
}

const EnhancedCopy = ({ enhancedCopy }: EnhancedCopyProps) => (
    <EnhancedCopyContainer dangerouslySetInnerHTML={{ __html: enhancedCopy }} />
)

export default EnhancedCopy

import React from 'react'
import unified from 'unified'
import parse from 'remark-parse'
import withAttrs from 'remark-attr'
import toHtml from 'remark-rehype'
import toReact from 'rehype-react'
import sanitize from 'rehype-sanitize'
import merge from 'lodash/merge'
import styled from 'styled-components'

// Fixme: https://issues.internations.org/browse/INOTF-1838
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import ghSchema from 'hast-util-sanitize/lib/github'
// import Collapsible from './Collapsible'

const schema = merge(ghSchema, {
    // Do not prefix potentially clobbering
    clobberPrefix: '',
    // Allow rel and target attrs for <a />
    attributes: { a: ['rel', 'target', 'href'] },
})

const processor = unified()
    .use(parse, { commonmark: true })
    .use(withAttrs)
    .use(toHtml)
    .use(sanitize, schema)
    .use(toReact, {
        createElement: React.createElement,
        Fragment: React.Fragment,
    })

const QAWrapper = styled.div`
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: #333;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
`

const Question = styled.div`
    font-weight: 600;
    margin-bottom: 8px;
`

const QAs = ({ qas }: { qas: { question: string; answer: string }[] }) => {
    return (
        <>
            {qas.map(item => {
                const { contents: Answer } = processor.processSync(item.answer)
                // const { contents: ShortenedAnswer } = processor.processSync(item.answer.substring(0, 900))
                return (
                    <QAWrapper key={item.question}>
                        <Question>{item.question}</Question>
                        {/* READ MORE is not working as Felix is expecting it, therefore he requested to deacivate it for now
                        <Collapsible
                            shortContent={<>{ShortenedAnswer}</>}
                            fullContent={<>{Answer}</>}
                            visibleTextHeight={2500}
                            isMasked={false}
                        /> */}
                        <div>{Answer}</div>
                    </QAWrapper>
                )
            })}
        </>
    )
}

export default QAs

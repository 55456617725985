import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { CorePage as WpCorePage, GeographyType, LinkBox as WpLinkBox } from '../types/wordpress'
import LinkBox from '../components/LinkBox'
import GuideLinkBox, { GuideType } from '../components/GuideLinkBox'
import PageHeader from '../components/PageHeader'
import GlobalPartners from '../components/GlobalPartners'
import LanguageLinks from '../components/LanguageLinks'
import Layout, { MainContent, SidebarContent } from '../components/Layout'
import UserTeasers from '../layouts/UserTeasers'
import NationalityList from '../components/NationalityList'
import Testimonials from '../components/Testimonials'
import USP from '../components/USP'
import { SidebarHeading } from '../components/typography'
import WhiteBox from '../components/WhiteBox'
import { StaticBanner, DynamicBanner } from '../components/StaticContent/StaticBanner'

import Events from '../layouts/EventTeasers'
import { Event as EventType } from '../components/EventTeaser'
import { ActivityGroup as ActivityGroupType } from '../components/GroupTeaser'
import { User as UserType } from '../components/UserTeaser'
import Groups from '../layouts/GroupTeasers'
import ContentBlock, { ToggledContentBlock } from '../layouts/ContentBlock'
import { BREAKPOINTS, PADDING_STANDARD } from '../components/tokens'
import NewRelicCustoms from '../components/NewRelicCustoms'
import useRedirect from '../hooks/useRedirect'
import useRegistrationPrefetch from '../hooks/useRegistrationPrefetch'
import useCookiebot from '../hooks/useCookiebot'
import useAppendedQueryParam from '../hooks/useAppendedQueryParam'
import useGoogleTargeting from '../hooks/useGoogleTargeting'

type PageTemplateProps = {
    pageContext: WpCorePage
    data: {
        allPastInterNationsEvent: {
            nodes: EventType[]
        }
        allFutureInterNationsEvent: {
            nodes: EventType[]
        }
        allPastInterNationsCountryEvent: {
            nodes: EventType[]
        }
        allFutureInterNationsCountryEvent: {
            nodes: EventType[]
        }
        allInterNationsGroups: {
            nodes: ActivityGroupType[]
        }
        allInterNationsUser: {
            nodes: UserType[]
        }
        allInterNationsGuide: {
            nodes: GuideType[]
        }
    }
}

const Content = styled.div`
    display: flex;
    flex-direction: column;
    @media (min-width: ${BREAKPOINTS.desktop}px) {
        flex-direction: row;
        z-index: 1;
    }
`

const HideAboveTablet = styled.div`
    display: block;
    @media (min-width: ${BREAKPOINTS.desktop}px) {
        display: none;
    }
`

const HideBelowTablet = styled.div`
    display: none;
    @media (min-width: ${BREAKPOINTS.desktop}px) {
        display: block;
    }
`

const Spacing = styled.div`
    margin-top: 16px;
`

const TestimonialSpacing = styled.div`
    margin-top: ${PADDING_STANDARD * 7}px;
`

type Toggled<T> = { enabled: false } | ({ enabled: true } & T)

const ToggledLinkBox = ({ linkBox, testClass }: { linkBox: Toggled<WpLinkBox>; testClass: string }) => {
    const { currentUrl, params } = useAppendedQueryParam()

    if (!linkBox.enabled) {
        return null
    }

    const links = linkBox.links.filter(link => link.link.title !== null)

    if (links.length === 0) {
        return null
    }

    return (
        <>
            <Spacing />
            <SidebarHeading>{linkBox.headline}</SidebarHeading>
            <WhiteBox>
                <LinkBox
                    links={links.map(({ link: { url, title } }) => ({
                        url,
                        title,
                        testClass: `${testClass}-${title.replace(/[\s\r\n\\]+/g, '-').toLowerCase()}`,
                    }))}
                    currentUrl={currentUrl}
                    params={params}
                />
            </WhiteBox>
        </>
    )
}

function isNotEmpty<T>(arr: T[]): arr is [T, ...T[]] {
    return 0 in arr
}

function trimGuideTopic(topic?: string): string | undefined {
    if (topic == null) {
        return undefined
    }
    return topic.replace('-short', '')
}

function getLocationIso(geography: GeographyType): string | undefined {
    if (geography.type === 'worldwide') {
        return undefined
    }
    if (geography.type === 'localcommunity') {
        return geography.localCommunity.country.countryCodeIso
    }
    if (geography.type === 'upcomingCommunity') {
        return geography.upcomingCommunity.country.countryCodeIso
    }
    if (geography.type === 'country') {
        return geography.country.countryCodeIso
    }
    throw new Error(`unexpected geography ${JSON.stringify(geography, null, 4)}`)
}

function extractEventNodes(geography: string, data: any) {
    if (geography === 'country') {
        return data.allFutureInterNationsCountryEvent.nodes.length > 0
            ? data.allFutureInterNationsCountryEvent.nodes
            : data.allPastInterNationsCountryEvent.nodes
    }

    return data.allFutureInterNationsEvent.nodes.length > 0
        ? data.allFutureInterNationsEvent.nodes
        : data.allPastInterNationsEvent.nodes
}

const CookiebotConfig = () => {
    return (
        <script
            id="CookiebotConfiguration"
            type="application/json"
            data-cookieconsent="ignore"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
                __html: `{
                        "Frameworks": {
                            "IABTCF2": {
                                "AllowedVendors": [755],
                                "AllowedGoogleACVendors": [],
                                "AllowedPurposes": [1, 2, 3, 4, 7, 9, 10],
                                "AllowedSpecialPurposes": [1, 2],
                                "AllowedFeatures": [1,3],
                                "AllowedSpecialFeatures": [1],
                                "VendorRestrictions": []
                            }
                        }
                    }`,
            }}
        />
    )
}

const PageTemplate = ({ pageContext, data }: PageTemplateProps) => {
    useCookiebot()
    useGoogleTargeting({
        ingocat: trimGuideTopic(pageContext.guideTopic),
        ingodest: getLocationIso(pageContext.geography),
    })
    useRedirect()
    useRegistrationPrefetch()
    const { currentUrl, params } = useAppendedQueryParam()
    const eventNodes = extractEventNodes(pageContext.geography.type, data)

    return (
        <>
            <CookiebotConfig />
            <PageHeader meta={pageContext.meta} />
            <Layout geography={pageContext.geography} currentUrl={currentUrl} params={params} hero={pageContext.hero}>
                <script
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                        __html: `performance.mark('hero rendered')`,
                    }}
                />

                {pageContext.hero.callToAction?.enabled === 'enabled' ? (
                    <DynamicBanner callToAction={pageContext.hero.callToAction} />
                ) : null}
                {pageContext.hero.callToAction?.enabled === 'static' ? <StaticBanner /> : null}

                <Content>
                    <MainContent>
                        {isNotEmpty(pageContext.testimonials) ? (
                            <HideAboveTablet>
                                <WhiteBox>
                                    <Testimonials testimonials={pageContext.testimonials} isBelowDesktop />
                                </WhiteBox>
                            </HideAboveTablet>
                        ) : null}
                        <ToggledContentBlock
                            className="t-seo-intro"
                            block={pageContext.intro}
                            id="description"
                            isIntro
                        />
                        {pageContext.seoContent2.map((block, i) => (
                            <ContentBlock className="t-seo-description" key={`seo-content-${i}`} block={block} /> // eslint-disable-line react/no-array-index-key
                        ))}
                        <USP />
                        {pageContext.feature.map((block, i) => (
                            <ContentBlock key={`feature-${i}`} block={block} /> // eslint-disable-line react/no-array-index-key
                        ))}
                        <ToggledContentBlock block={pageContext.statsContent} id="statsContent" areStats />
                        {pageContext.seoContent4.map((block, i) => (
                            <ContentBlock className="t-seo-description" key={`seo-content-${i}`} block={block} /> // eslint-disable-line react/no-array-index-key
                        ))}
                        <ToggledContentBlock block={pageContext.events} id="events">
                            <Events events={eventNodes} currentUrl={currentUrl} params={params} />
                        </ToggledContentBlock>

                        {pageContext.seoContent3.map((block, i) => (
                            <ContentBlock className="t-seo-description" key={`seo-content-${i}`} block={block} /> // eslint-disable-line react/no-array-index-key
                        ))}

                        <ToggledContentBlock block={pageContext.memberList} id="members" isList>
                            <UserTeasers users={data.allInterNationsUser.nodes} />
                        </ToggledContentBlock>
                        <HideAboveTablet>
                            <>
                                <Spacing />
                                <GlobalPartners />
                            </>
                        </HideAboveTablet>
                        <ToggledContentBlock block={pageContext.groups} id="group">
                            <Groups groups={data.allInterNationsGroups.nodes} currentUrl={currentUrl} params={params} />
                        </ToggledContentBlock>
                        {pageContext.seoContent.map((block, i) => (
                            <ContentBlock className="t-seo-description" key={`seo-content-${i}`} block={block} /> // eslint-disable-line react/no-array-index-key
                        ))}
                    </MainContent>
                    <SidebarContent>
                        <Spacing />
                        {isNotEmpty(pageContext.testimonials) ? (
                            <>
                                <HideBelowTablet>
                                    <WhiteBox>
                                        <Testimonials testimonials={pageContext.testimonials} />
                                    </WhiteBox>
                                </HideBelowTablet>
                                <Spacing />
                            </>
                        ) : (
                            <TestimonialSpacing />
                        )}
                        <Spacing />
                        <HideBelowTablet>
                            <GlobalPartners />
                        </HideBelowTablet>

                        <ToggledLinkBox testClass="t-community-list-entry" linkBox={pageContext.communityLinks} />
                        {pageContext.nationalityLinks.enabled && pageContext.nationalityLinks.links.length ? (
                            <>
                                <Spacing />
                                <SidebarHeading>{pageContext.nationalityLinks.headline}</SidebarHeading>
                                <WhiteBox noPadding>
                                    <NationalityList
                                        nationalities={pageContext.nationalityLinks.links.map(
                                            ({ link: { url, title }, nationality }) => ({
                                                url,
                                                title,
                                                iocCode: nationality.countryCodeIoc,
                                            }),
                                        )}
                                        currentUrl={currentUrl}
                                        params={params}
                                    />
                                </WhiteBox>
                            </>
                        ) : null}

                        {isNotEmpty(data.allInterNationsGuide.nodes) && pageContext.guideLinks.enabled ? (
                            <>
                                <Spacing />
                                <GuideLinkBox
                                    headline={pageContext.guideLinks.headline}
                                    testClass="t-guide-categories"
                                    guides={data.allInterNationsGuide.nodes[0].guides}
                                    currentUrl={currentUrl}
                                    params={params}
                                />
                            </>
                        ) : null}

                        <ToggledLinkBox
                            testClass="t-upcoming-community-list"
                            linkBox={pageContext.upcomingCommunityLinks}
                        />
                        {pageContext.languageLinks.enabled && pageContext.languageLinks.links.length ? (
                            <>
                                <Spacing />
                                <SidebarHeading>{pageContext.languageLinks.headline}</SidebarHeading>
                                <WhiteBox>
                                    <LanguageLinks
                                        links={pageContext.languageLinks.links}
                                        currentUrl={currentUrl}
                                        params={params}
                                    />
                                </WhiteBox>
                            </>
                        ) : null}
                    </SidebarContent>
                </Content>
            </Layout>
            <NewRelicCustoms page={pageContext} />
        </>
    )
}
export const query = graphql`
    query(
        $pastEventsFilter: InterNationsEventFilterInput
        $futureEventsFilter: InterNationsEventFilterInput
        $pastCountryEventsFilter: InterNationsEventFilterInput
        $futureCountryEventsFilter: InterNationsEventFilterInput
        $groupsFilter: InterNationsGroupsFilterInput
        $userFilter: InterNationsUserFilterInput
        $guideFilter: InterNationsGuideFilterInput
    ) {
        allFutureInterNationsEvent: allInterNationsEvent(
            filter: $futureEventsFilter
            sort: { fields: [startDate], order: ASC }
            limit: 4
        ) {
            nodes {
                id
                url
                startDate
                endDate
                name
                description
                localcommunityId
                attendees
                image
                location {
                    name
                    address {
                        streetAddress
                        addressLocality
                    }
                }
            }
        }
        allFutureInterNationsCountryEvent: allInterNationsEvent(
            filter: $futureCountryEventsFilter
            sort: { fields: [startDate], order: ASC }
            limit: 4
        ) {
            nodes {
                id
                url
                startDate
                endDate
                name
                description
                localcommunityId
                attendees
                image
                location {
                    name
                    address {
                        streetAddress
                        addressLocality
                    }
                }
            }
        }
        allPastInterNationsEvent: allInterNationsEvent(
            filter: $pastEventsFilter
            sort: { fields: [startDate], order: DESC }
            limit: 4
        ) {
            nodes {
                id
                url
                startDate
                endDate
                name
                description
                localcommunityId
                attendees
                image
                location {
                    name
                    address {
                        streetAddress
                        addressLocality
                    }
                }
            }
        }
        allPastInterNationsCountryEvent: allInterNationsEvent(
            filter: $pastCountryEventsFilter
            sort: { fields: [startDate], order: DESC }
            limit: 4
        ) {
            nodes {
                id
                url
                startDate
                endDate
                name
                description
                localcommunityId
                attendees
                image
                location {
                    name
                    address {
                        streetAddress
                        addressLocality
                    }
                }
            }
        }
        allInterNationsGroups(filter: $groupsFilter, limit: 4) {
            nodes {
                id
                url
                title
                moodImage
            }
        }
        allInterNationsUser(filter: $userFilter, sort: { fields: [id], order: DESC }, limit: 10) {
            nodes {
                id
                gender
                imagePath
                localcommunityId
                localcommunityName
                countryOfOriginCode {
                    name
                    iocCode
                }
                countryOfResidencyCode {
                    name
                    iocCode
                }
            }
        }
        allInterNationsGuide(filter: $guideFilter) {
            nodes {
                name
                slug
                location_type
                guides {
                    title
                    url
                }
            }
        }
    }
`

export default PageTemplate
